<template>
	<div class="row">
		<div class="col-md-10">
      <div>
      <h1 style="text-align:center;">Privacy Policy</h1>
     
      <div style="margin-top:30px;">
        <p class="p-class">
         We collect and generate various data points about you.
        Data by which you are identified is termed as “Personal Data”. Personal Data does not mean information that is freely available or accessible in public domain.
        Your Privacy is of utmost importance to us and protection of your Personal Data is a key commitment for us.

        </p>
      </div>
      </div>
      <div>
       <h1>I. Personal Data</h1>
      <div>
        <div>Personal Data we may collect from you are as under:</div>
        <div>1. Personal details (e.g. name, contact details including, residential address, date of birth, documents such as identity 
            card / passport details / and/or education details) provided by you to us to avail various products/services from us.
        </div>
        <div>2. Your details including transaction history, balances, payment details, for effecting transfer of monies through 
            various payment channels provided by us.
        </div>
        <div>2. Your details including transaction history, balances, payment details, for effecting transfer of monies through 
            various payment channels provided by us.
        </div>
        <div>
            3. financial details (e.g. income, expenses, and/or credit history) needed as part of request for some of our products/services;
        </div>
        <div>
            5. voice recordings of our conversations with our customer care agent with you to address your queries/grievances;
        </div>
        <div>
           6. employment details (e.g. occupation, positions held, employment history, salary and/or benefits) as part of our record retention for credit/various product evaluations
        </div>
        <div>
           7. specimen signature(s) for processing of your instructions received by us through our various payment and delivery channels;
        </div>
        <div>
          8. opinions provided by you to us by way of feedback or responses to surveys;
        </div>
        <div>
         9. information obtained from your mobile device by way of using our app like device location, communication information including device information (including storage, model, mobile network), transactional and promotional SMS/app notifications.
        </div>
      </div>
      <div>
        <h1>II. Sharing of your Personal Data</h1>
        <div>
          Any Personal Data that we have access to shall never be shared without your consent.
          In various processes / submission of applications / availment of product/service offerings, we even seek your explicit consent to use / share your Personal Data.
          In our business and operational processes, we only share the data on a partial and “need-to-know” basis to designated personnel or partners or service providers.
          We will share your data with competent/ legal/statutory/regulatory agencies / authorities or partners/service providers acting on our behalf (as the case may be) in following cases:

        </div>
        <div>
          1. only for enabling the provision of the products/services availed by you, strictly on a “need to know” basis and subject to applicable laws.
        </div>
        <div>
          2. it is directed or required by legal/regulatory / statutory / governmental authorities under applicable laws/regulations though a legally obligated request.
        </div>
        <div>
          3. it is required by financial institutions to verify, mitigate or prevent fraud or to manage risk or recover funds in accordance with applicable laws/regulations.
        </div>
      
	    </div>
      <div>
        <h1>III. Usage of Your Personal Data
        </h1>
        <div>
          We use your Personal Data in our business activities for providing our or our partners’ products/services and to perform, among other actions, the following:
        </div>
        <div>
          1. to facilitate the transactions or report on these transactions;

        </div>
        <div>
          2. it is directed or required by legal/regulatory / statutory / governmental authorities under applicable laws/regulations though a legally obligated request.
        </div>
        <div>
          2. to undertake research and analytics for offering or improving our products/services and their security and service quality;

        </div>
        <div>
          3. to check and process your applications submitted to us for products/services and/or instructions or requests received from you in respect of these products/services;
        </div>
        <div>
          4. to share with you, updates on changes to the products/services and their terms and conditions;
        </div>
        <div>
          5. to take up or investigate any complaints/claims/disputes;
        </div>
        <div>
          6. to respond to your queries or feedback submitted by you;

        </div>
        <div>
          7. to verify your identity for us to provide products/services to you;
        </div>
        <div>
          8. to carry credit checks, screenings or due diligence checks as lawfully required by us;

        </div>
        <div>
          9. to monitor and review products/services from time to time;
        </div>
        <div>
          10. to undertake financial/regulatory/management reporting, and create and maintain various risk management models;
        </div>
        <div>
          11. for conducting audits and for record keeping purposes;
        </div>
        <div>
          12. for selective offers and promotions.
        </div>
        <div>
          <span style="font-weight: 600; font-size: 14px;">External Processing:</span> We may provide your personal information to our affiliates or other trusted businesses or persons or service providers engaged by us, or institutions that we partner with to assist us with providing you with products/services to better serve your needs and interests, based on your instructions and in compliance with our Privacy Policy and any other appropriate confidentiality and security measures.
We also use your Personal Data to fulfill the requirements of applicable laws/regulations and/or court orders/regulatory directives received by us.

        </div>
      
	    </div>
      <div>
        <h1>IV. Purging of your Personal Data
        </h1>
        <div>
          1. You may delete your Supiri pola (සුපිරි පොළ) account at any point of time by making such choice in Supiri pola (සුපිරි පොළ) E-commerce’s desktop website, mobile WAP site or mobile application. With this we will no longer provide your data for external processing as mentioned above. However, we retain your Personal Data as long as the purpose for its usage exists, after which the same is disposed off by us except for any record retention required as per applicable law.

        </div>
        <div>
          2. The provisions of various laws require your transaction logs to be stored for longer periods post the deletion of an account. Further, in the event of the pendency of any legal/regulatory proceeding or receipt of any legal and/or regulatory direction to that effect, we may be required by the law of the land to retain your Personal Data for longer periods.

        </div>
	    </div>
      <div>
        <h1>V. Cookie Policy
        </h1>
        <div>
          1. Please note that a “cookie” is a small piece of information stored by a web server on a web browser so it can be later read back from that browser.
        </div>
        <div>
          2. We may use cookie and tracking technology depending on the features offered.
        </div>
        <div>
          3. No Personal Data will be collected via cookies and other tracking technology; however, if you previously provided Personal Data, cookies may be tied to such information.
        </div>
	    </div>
      <div>
        <h1>VI. Links to other websites

        </h1>
        <div>
          Our website may contain links to other websites which are not maintained by us. This privacy policy only applies to us. You are requested to read the other websites’ privacy policies when visiting these websites.
        </div>
	    </div>
      <div>
        <h1>VII. Reasonable Security Practices and Procedures
        </h1>
        <div>
          We take various steps and measures to protect the security of your Personal Data from misuse, loss, unauthorised access, modification or disclosure. We use latest secure server layers encryption and access control on our systems. Our safety and security processes are audited by a third party cyber security audit agency from time to time.
We have provided multiple levels of security to safeguard your app by Login/Logout option and AppLock feature that can be enabled by you. We also ensure the device binding so that the same login cannot be used on different device without any additional authentication/OTP. Please do not share your Supiri pola (සුපිරි පොළ) login, password and OTP details with anybody.

        </div>
	    </div>
      <div>
        <h1>VIII. Contact us
        </h1>
        <div>
          You may contact us on any aspect of this policy or for any discrepancies/grievances with respect to your Personal Data, by writing to our Grievance Officer at Supiri pola (සුපිරි පොළ) Supreme E-commerce Private Limited,Level 33, West Tower, World Trade Center, Colombo
        </div>
        <div>
          01, Sri Lanka.
        </div>
	    </div>
      <div>
        <h1>IX. Policy Review & Updates
        </h1>
        <div>
          This policy will be reviewed by us as and when required and the same may change at any time. While we will make reasonable efforts to keep you posted on any updates to this privacy policy, to make sure that you are aware of any changes, we recommend that you review this policy periodically.
This Privacy Policy shall apply uniformly to Supiri pola (සුපිරි පොළ) E-commerce’s desktop website, mobile WAP site & mobile applications
In this policy, the words “we”, “ours” and/or “us” refer to Supiri pola (සුපිරි පොළ) Supreme E-commerce Private Limited and “you” and/or “your” refer to our customers.

        </div>
	    </div>
	    </div>
    </div>		
  </div>
</template>
<script>
export default { 
components: {
	
},
  data () { 
    return {
    }
  },
  metaInfo: {
    title: 'Privacy Policy',
    titleTemplate: '%s | Online Shopping Sri Lanka | Daily Essentials',
    meta: [
        { charset: 'utf-8' },
        { name: 'description', content: 'Supiri Pola Online shopping site in Sri Lanka is available for your Daily Essential Needs. Online Shop in Sri Lanka.We deliver Grocery, Daily Essentials and more...' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ],
    htmlAttrs: {
      lang: 'en',
      amp: true
    }
  },
watch: {
	
},
computed: {

},
mounted: function() {

},
created(){
},
methods: {

	
	

	

	
  }  
}
</script>
<style scoped>
.p-class{
  text-align:left; 
  font-size:14px;
}
.dot {
  height: 5px;
  width: 5px;
  background-color: black;
  border-radius: 50%;
  margin-right:10px;
  margin-bottom:2px;
  display: inline-block;
}
.dot {
  height: 5px;
  width: 5px;
  background-color: black;
  border-radius: 50%;
  margin-right:10px;
  margin-bottom:2px;
  display: inline-block;
}
.square {
  height: 5px;
  width: 5px;
  background-color: black;
  margin-right:10px;
  margin-bottom:2px;
  display: inline-block;
}

</style>
